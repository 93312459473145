// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"775e89d7d3ea90bb3c8da068f4c9a888b2648753"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import posthog from 'posthog-js'

const defaultSampleRate = 0.1; // 10%;
const criticalSampleRate = 0.25; // 25%

posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY || "", {
  api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://us.i.posthog.com",
})

Sentry.init({
  dsn: "https://3734aee1489adb3462497d2f13b657ba@o4506618145538048.ingest.us.sentry.io/4507505139056640",

  tracesSampler: ({ name, attributes, parentSampled }) => {
    // ? add specific transactions here if they should have higher/lower sample rates than the default
    if (name.includes("api/document")) {
      // Drop this completely by setting its sample rate to 0%
      return criticalSampleRate;
    }

    // ? otherwise this is the default rate
    return defaultSampleRate;
  },

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: true,


  integrations: [
    posthog.sentryIntegration({
      organization: 'ksense-tech',
      projectId: 4507505139056640,
      severityAllowList: ['error']
    }
  )
  ],
});
